<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
        @click-row="clickSummaryRow"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            :estado-cliente="formattedItem.estadoCliente"
            :estado-sistema="formattedItem.estadoSistema"
          />
        </template>
      </b10-view-summary>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './SistemaViewData'
import { nonEmpty } from '@/utils/templates'
import { TABLA, SISTEMA, TFICHERO } from '@/utils/consts'
import { captureGeolocation, getLatLongURL } from '@/utils/maps'
import { get } from 'vuex-pathify'
import ExtraSubtitle from '../../components/SistemaExtraSubtitle'
import { encodeBase64 } from '@/utils/router'


export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [],
      showingDialogs: {
        moreInfo: false,
      },
      toolbarOptions: {
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
        geolocalizar: {
          title: 'Capturar geolocalización actual',
          visible: true,
          icon: 'mapLocation',
        },
        irCliente: {
          title: 'Ir al cliente',
          visible: true,
        },
        parteNuevo: {
          title: 'Nuevo parte',
          visible: true,
          icon: 'add',
        },
      },
    }
  },
  computed: {
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    appComerciales: get('usuario/appComerciales'),
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idcliente
        item.title = `${item.descripcion} (${item.codigo})`
        item.subtitle = this.$options.filters.linebreaksBr(nonEmpty`
          ${item.cliente_nombre} (${item.idcliente})
          ${item.sistema_direccion}
          ${item.cliente_direccion_observacion}
          Sistema de ${item.tsistema_descripcion}`)
        item.estadoCliente = item.estado_cliente
        item.estadoSistema = item.estado
        if (item.avisos) {
          item.alerts = [{
            value: this.$options.filters.linebreaksBr(item.avisos),
            type: 'warning',
            options: {
              persistent: true,
            },
          }]
        } else {
          item.alerts = []
        }
        if (!item.latitud || !item.longitud) {
          item.alerts.push({
            value: 'Sistema sin geolocalización',
            type: 'warning',
            options: {
              persistent: true,
              actions: [{ text: 'Capturar', flat: true, handler: this.geolocalizar }],
            },
          })
        }
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = `Sistema Nº${this.item.dataset.codigo}`
      // asignar moreInfoRows en loadPage() para tener accesibles los permisos del store (no disponibles en data())
      this.moreInfoRows = [
        'codigo',
        'nabonado1',
        'descripcion',
        { name: 'idcliente', clickable: true },
        'fconexion',
        'sistema_direccion',
        'cliente_direccion_observacion',
        'zona_descripcion',
        'horario',
        'grado_seguridad_descripcion',
        'tactividad_descripcion',
        'tsistema_descripcion',
        'central_alarma_nombre',
        { name: 'forma_pago_descripcion', visible: this.hasViewPerm(this.permissions.clientes.verFormaPago) },
        {
          name: 'riesgo_disponible',
          label: 'Riesgo disponible',
          visible: this.hasViewPerm(this.permissions.clientes.verRiesgoDisponible),
          clickable: true,
          icon: this.$vuetify.icons.values.show,
          value: 'Ver',
        },
        'agente_nombre',
        'comercial_nombre',
        'observaciones',
      ]
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      this.toolbarOptions.geolocalizar.visible = this.hasEditPerm(this.permissions.sistema.id)
      try {
        // Si no tengo permisos para ver todos los sistemas entonces tengo que poder ver los sistemas en los que tengo partes asignados
        // si no es técnico entonces no debería verlo
        let usuarioIdtecnico = null
        if (!this.hasViewPerm(this.permissions.sistema.id)) {
          if (this.usuarioIdtecnico) {
            usuarioIdtecnico = this.usuarioIdtecnico
          } else {
            this.itemNotFound()
          }
        }
        const [dataset, metadata] = await Data.selectSistema(this, this.routeParams.idsistema, usuarioIdtecnico)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
          this.toolbarOptions.map.visible = !!this.item.dataset.latitud && !!this.item.dataset.longitud
          this.toolbarOptions.irCliente.visible = (
            !!this.item.dataset.idcliente && this.hasViewPerm(this.permissions.cliente.id)
          )
          this.toolbarOptions.parteNuevo.visible = this.hasPerm(this.permissions.partes.nuevoParteTrabajo) && this.item.dataset.estado === SISTEMA.estados.activo
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const resp = await Data.selectDetails(
        this,
        this.routeParams.idsistema,
        this.item.dataset.idcliente,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante
      )
      // plano
      if (resp.data.selectFicheroPlano.result.dataset.length > 0) {
        const detailPlano = this.addDetail('plano', 'Planos', 'Plano del sistema', 'plano')
        detailPlano.badge = resp.data.selectFicheroPlano.result.dataset[0].count
      }
      // contactos
      if (this.hasViewPerm(this.permissions.sistemaTelefono.id)) {
        const detailContactos = this.addDetail(
          'contactos', 'Personas de contacto', 'Personas de contacto del sistema', 'personaContacto'
        )
        const [ datasetPersonasContacto ] = await this.$online.sistemaTelefono.selectContactosDelSistema(this.routeParams.idsistema, this.item.dataset.idcliente)
        detailContactos.badge = datasetPersonasContacto.length
      }
      // Revisiones (sistema_mant)
      const detailRevisiones = this.addDetail(
        'revisiones', 'Revisiones', 'Revisiones del sistema', 'revision'
      )
      detailRevisiones.badge = resp.data.sistemaMantCount.result.dataset[0].count || 0
      // Material sistema (material_sistema)
      if (this.hasViewPerm(this.permissions.materialSistema.id)) {
        const detailMaterialSistema = this.addDetail(
          'materialSistema', 'Material de sistema', 'Material instalado en el sistema', 'materialInstalar'
        )
        detailMaterialSistema.badge = resp.data.selectMaterialSistema.result.dataset[0].count || 0
      }
      // órdenes de trabajo
      this.detailOrdenesTrabajo = this.addDetail(
        'ordenes', 'Órdenes de trabajo', 'Órdenes de trabajo del sistema', 'orden'
      )
      // servicios contratados (sistema_cuota)
      if (this.hasViewPerm(this.permissions.sistemaCuota.id)) {
        const detailServiciosContratados = this.addDetail(
          'serviciosContratados', 'Servicios contratados', 'Servicios contratados y cuotas de mantenimiento', 'servicioContratado'
        )
        detailServiciosContratados.badge = resp.data.selectSistemaCuotaCount.result.dataset[0].count || 0
      }
      // Vias de comunicación
      const detailViasComunicacion = this.addDetail(
        'viasComunicacion', 'Vías de comunicación', 'Vías de comunicación del sistema', 'viaComunicacion'
      )
      detailViasComunicacion.badge = resp.data.sistemaTviacomunicacionCount.result.dataset[0].count || 0
      // ficheros
      if (this.hasViewPerm(this.permissions.sistemaAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'adjuntos', 'Adjuntos', 'Fotografías y ficheros adjuntos', 'attach'
        )
        detailFicheros.badge = resp.data.selectFicheroCount.result.dataset[0].count || 0
      }
      // datos
      if (this.hasViewPerm(this.permissions.sistemaDato.id)) {
        const detailDatoSistema = this.addDetail(
          'datos', 'Datos', 'Datos de sistema', 'datoPersonalizado'
        )
        detailDatoSistema.badge = resp.data.selectDatoSistemaCount.result.dataset[0].count || 0
      }
      // operaciones CRM
      if (this.appComerciales && this.hasViewPerm(this.permissions.operaciones.id)) {
        const detailOperacionCRM = this.addDetail(
          'operacionCRM', 'Operaciones', 'Operaciones relacionadas con el sistema', 'operacionCRM'
        )
        detailOperacionCRM.badge = resp.data.selectOperacioncrmCount.result.dataset[0].count || 0
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'contactos') {
        this.$appRouter.push({
          name: 'telefonos__sistemas-cliente-telefono-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'viasComunicacion') {
        this.$appRouter.push({
          name: 'sistemas__sistema-tviacomunicacion-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'adjuntos') {
        this.$appRouter.push({
          name: 'ficheros__sistemas-fichero-list',
          params: {
            id: this.routeParams.idsistema,
            idtabla: TABLA.sistema.idtabla,
          },
        })
      } else if (data.detail.name === 'revisiones') {
        this.$appRouter.push({
          name: 'sistemas__sistema-mant-list',
          params: {
            idsistema: this.routeParams.idsistema
          }
        })
      } else if (data.detail.name === 'operacionCRM') {
        this.$appRouter.push({
          name: 'operacionescrm__sistemas-operacioncrm-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'ordenes') {
        this.$appRouter.push({
          name: 'sistemas__ordenes-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'serviciosContratados') {
        this.$appRouter.push({
          name: 'sistemas__sistema-cuota-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'materialSistema') {
        this.$appRouter.push({
          name: 'sistemas__subsis-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      } else if (data.detail.name === 'datos') {
        this.$appRouter.push({
          name: 'sistemas__dato-sistema-list',
          params: {
            idsistema: this.routeParams.idsistema,
          },
        })
      }
      else if (data.detail.name === 'plano') {
        this.$appRouter.push({
          name: 'ficheros__sistemas-fichero-list',
          params: {
            id: this.routeParams.idsistema,
            idtabla: TABLA.sistema.idtabla,
          },
          query: {
            _filter: encodeBase64({
              tipoFicheroClasificacion: {
                value: [TFICHERO.clasificacion.planos],
                options: { fixed: true },
              },
            })
          },
        })
      }
    },
    async clickSummaryRow (row) {
      switch (row.name) {
      case 'idcliente':
        this.showingDialogs.moreInfo = false
        this.$appRouter.push({
          name: 'clientes__cliente-view',
          params: {
            idcliente: this.item.dataset.idcliente,
          }
        })
        break
      case 'riesgo_disponible':
        const riesgoDisponible = this.$options.filters.currency(
          await Data.calcRiesgoDisponible(this, this.item.dataset.idcliente)
        )
        this.setMoreInfoRowValue(row.name, 'value', riesgoDisponible)
        this.setMoreInfoRowValue(row.name, 'clickable', false)
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.map) {
        window.open(getLatLongURL(this.item.dataset.latitud, this.item.dataset.longitud), '_blank')
      } else if (option === this.toolbarOptions.geolocalizar) {
        this.geolocalizar()
      } else if (option === this.toolbarOptions.irCliente) {
        this.$appRouter.push({
          name: 'clientes__cliente-view',
          params: {
            idcliente: this.item.dataset.idcliente,
          },
        })
      } else if (option === this.toolbarOptions.parteNuevo) {
        this.$appRouter.push({
          name: 'partes__parte-trabajo-add',
          query: {
            idsistema: this.routeParams.idsistema,
          },
        })
      }
    },
    async geolocalizar () {
      const geolocation = await captureGeolocation(this)
      if (!!geolocation?.lat && geolocation?.lng) {
        const res = await this.$alert.showConfirm(
          `¿Deseas asignar la geolocalización actual (${geolocation.lat}, ${geolocation.lng})
          a la dirección del sistema "${this.item.dataset.sistema_direccion}"?`)
        if (res) {
          await this.$online.clienteDireccion.geolocalizar(
            this,
            this.item.dataset.idcliente_direccion,
            geolocation.lat,
            geolocation.lng
          )
          await this.loadItem()
        }
      }
    },
  },
}
</script>
